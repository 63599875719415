@import '../../index.scss';

.list__container,
.list__container_loading,
.list__init__display_container,
.list__container_control,
.list__container_control > button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;

  height: 100%;
  width: 100%;
}

.list__container {
  gap: 8px;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 480px;

  opacity: 0;
  transform: translateY(-25px);

  border-radius: 8px;
  background-color: $background-user-list;
  padding: 16px 8px;
  transition: background-color 0.5s ease-in-out;
  animation: dissolve 0.5s ease-in-out 0.5s forwards;
}

@keyframes dissolve {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.list__container_control {
  justify-content: center;
  flex-flow: row nowrap;
  gap: 8px;
  width: 100%;
  height: 48px;
}

.list__container_control > button {
  justify-content: center;
  width: 48px;
  font-size: 32px;
  color: $primary;
}

.list__container_control > button:disabled {
  color: $disabled-input;
  cursor: default;
}

.list__container_control > p {
  font-size: 16px;
}

.list__container_loading {
  margin: 48px 0px;
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
}

.list__init__display_container {
  justify-content: center;
  gap: 8px;
  height: 100%;
  width: 100%;
  letter-spacing: 2px;
}

.list__init__display_icon {
  font-size: 36px;
  color: $primary;
}

.list__container::-webkit-scrollbar {
  width: 8px;
}

.list__container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #0000001a;
  border-radius: 8px;
}

.list__container::-webkit-scrollbar-thumb {
  background-color: #707070;
  border-radius: 8px;
}
