@import '../../index.scss';

.list__item__container {
  width: 100%;
  min-height: 64px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 8px;
  border-radius: 8px;

  background-color: $background;
  box-shadow: -4px 4px 16px 0px #0000000c;
  -webkit-box-shadow: -4px 4px 16px 0px #0000000c;
  -moz-box-shadow: -4px 4px 8px 16px #0000000c;

  cursor: default;
}

.list__item__container > p,
a {
  font-weight: 700;
  color: $primary;
  text-align: left;
}

.list__item__container > img {
  width: 48px;
  height: auto;
  border-radius: 50%;
  border: 1px solid $border-color-primary;
}
