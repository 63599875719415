* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;
  height: max-content;
  width: 100%;
}

button {
  border: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
}

button:focus {
  outline: none;
  box-shadow: none;
}

#root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
}
