@import '../../index.scss';

.user_list__container,
.user_list__container_loading,
.user_list__init__display_container,
.user_list__container_control,
.user_list__container_control > button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;

  height: 100%;
  width: 100%;
}

.user_list__container {
  gap: 8px;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 480px;

  border-radius: 8px;
  background-color: $background-user-list;
  padding: 16px 8px;
  transition: background-color 0.5s ease-in-out;
}

.user_list__container_control {
  justify-content: center;
  flex-flow: row nowrap;
  gap: 8px;
  width: 100%;
  height: 48px;
}

.user_list__container_control > button {
  justify-content: center;
  width: 48px;
  font-size: 32px;
  color: $primary;
}

.user_list__container_control > button:disabled {
  color: $disabled-input;
  cursor: default;
}

.user_list__container_control > p {
  font-size: 16px;
}

.user_list__container_loading {
  margin: 48px 0px;
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
}

.user_list__init__display_container {
  justify-content: center;
  gap: 8px;
  height: 100%;
  width: 100%;
  letter-spacing: 2px;
}

.user_list__init__display_icon {
  font-size: 36px;
  color: $primary;
}

.user_list__container::-webkit-scrollbar {
  width: 8px;
}

.user_list__container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #0000001a;
  border-radius: 8px;
}

.user_list__container::-webkit-scrollbar-thumb {
  background-color: #707070;
  border-radius: 8px;
}
