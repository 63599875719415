@import '../index.scss';

.container__main {
  min-height: 100vh !important;
  height: max-content !important;
  gap: 16px;
  justify-content: flex-start !important;
  flex-flow: row nowrap;
  opacity: 0;
  transform: translateY(-50px);
  animation: dissolve 0.5s ease-in-out 0.5s forwards;
}

@keyframes dissolve {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
