$primary: #5656fd;
$secondary: #ff4d4d;
$disabled-input: #dfdfdf;

$font: #1f1f1f;

$input-placeholder: #9d9dff;
$border-color-primary: #c0c0c0;
$border-color-secondary: #ebebeb;

$background: #fff;
$background-user-list: #fcfcfc;
