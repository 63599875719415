@import '../styles/theme.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  color: $font;
  height: 100vh;
  width: 100%;
  padding: 8px;
}
